import { combineReducers } from 'redux';
import settings from './settings/reducer';
import fulfillment from './fulfillment/fulfilmentReducer';
import contract from './contract/contractReducer';



const reducers = combineReducers({

  settings,
  fulfillment,
  contract,

});

export default reducers;