import React, { Suspense } from 'react';
import './App.scss';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
// import axios from 'axios';
// import { toast } from 'react-toastify';

// const App = React.lazy(() => import('./App'));

// axios.interceptors.request.use((req) => {
//   console.log('1 Posted Successfully');
//   // Add configurations here
//   return req;
// }, (err) => {
//   console.log("1 error console")
//   console.log(err.response)
//   if (err.response.data.message == "Your account is disabled please contact to recity") {
    
    

//     setTimeout(()=>{
//       localStorage.clear();
//       // window.location = "/user/login";
//       this.props.history.push("/user/login");
//     },3000)
    
//   }
//   return Promise.reject(err);
// });
// // For POST requests
// axios.interceptors.response.use((res) => {
//   console.log('2 Posted Successfully');
//   // Add configurations here
//   if (res.status === 201) {
//     console.log('3 Posted Successfully');
//   }
//   return res;
// }, (err) => {
//   console.log("2 error console")
//   console.log(err.response)
//   if (err.response.data.message == "Your account is disabled please contact to recity") {

//     // toast.error(err.response.data.message)
//     setTimeout(()=>{
//       localStorage.clear();
//       window.location = "/user/login";
//       // this.props.history.push("/user/login");
//     },5000)

//   }


//   return Promise.reject(err);
// });

const App = React.lazy(() => import('./App' ));


ReactDOM.render(
  <Provider store={configureStore()}>
    <Suspense fallback={<div className="loading" />}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById('root')
);
/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
