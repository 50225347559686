export default function reducer(state = false, actions) {
    switch (actions.type) {
        case 'SET_SPINNER':
            return {
                ...state,
                isLoader: actions.payload,
            };

        default:
            return state;
    }
}